.map_point {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: red;
        color: #FFFFFF;
    }

    &:active {
        background-color: red;
        color: #FFFFFF;
    }
}


.mapboxgl-ctrl-top-left {
    display: none;
}

.geo_marker {
    width: 50px;
    height: 50px;
}

.geolocation {
    position: relative;
}

.geo_backround {
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -56.5%) scale(1.2);
    background: rgba(255, 0, 0, 0.2);
    animation: circle 1.5s linear infinite normal running 0s none;
}

@keyframes circle {

    0% {
        transform: translate(-50%, -55%) scale(1)
    }

    100% {
        transform: translate(-50%, -55%) scale(3)
    }
}


.clear_route {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 100;
    border: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    background: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    font-size: 18px;
    transition: .1s;
    user-select: none;

    &:hover {
        background: #ECF2F4;
    }

    &:active {
        transform: scale(.9);
    }
}