.description {
    max-width: 1600px;
    margin: 10px auto 0;
}

@media (max-width: 1625px) {
    .description  {
        padding: 0 25px;
    }
}
@media (max-width: 768px) {
    .description  {
        padding: 0 15px;
    }
}
.title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1.08px;
    text-transform: uppercase;
}
@media (max-width: 768px) {
    .title  {
        font-size: 20px;
    }
}
.body {
    margin-top: 50px;
    display: flex;
    gap: 40px;
}


@media (max-width: 1300px) {
    .body {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}
.text {
    font-size: 20px;
    line-height: 150%;
    p:not(:first-child) {
        margin-top: 15px;
    }
}
@media (max-width: 768px) {
    .text {
        font-size: 16px;
    }
}
.img {
    flex: 1 0 50%;
    img {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .img img {
        max-width: 100%;
    }
}