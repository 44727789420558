.navigation {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    line-height: 150%;
    max-width: 1600px;
    align-self: start;
}

@media (max-width: 1625px) {
  .navigation {
    padding: 0 25px;
  }
}

@media (max-width: 768px) {
  .navigation {
    display: none;
  }
}

.main_link {
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid #000000;
    }
}

.page_name {
  color: #538146;
  border-bottom: 1px solid transparent;
}