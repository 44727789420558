.wrapper {
    margin-top: 160px;
    background-color: #ECF2F4;
    padding: 60px 0;
}
@media (max-width: 1625px) {
    .wrapper {        
        padding: 60px 25px;
    }
}
@media (max-width: 768px) {
    .wrapper {
        margin-top: 0;
        background: none;
        padding: 0 15px;
    }
}

.upperBlock {
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
    display: flex;
    height: 180px;
    justify-content: space-between;
}

@media (max-width: 1625px) {
    .upperBlock {        
        height: calc(1em + 12vw);
    }
}

.titles {
    align-self: center;
}
@media (max-width: 768px) {
    .upperBlock {
        flex-direction: column-reverse;
        align-items: center;
        height: auto;
    }
}

.title_high {
    font-size: 44px;
    font-weight: 500;
    letter-spacing: 0.88px;
    color: #606060;
}
@media (max-width: 1625px) {
    .title_high {        
        font-size: calc(1em + 2vw)
    }
}
@media (max-width: 768px) {
    .title_high {
        font-size: 20px;
        text-align: center;
    }
}

.title_main {
    font-size: 90px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
}
@media (max-width: 1625px) {
    .title_main {        
        font-size: calc(1em + 4vw)
    }
}
@media (max-width: 768px) {
    .title_main {
        font-size: 28px;
        text-align: center;
    }
}
.img_body {
    text-align: right;;
}
.img {
    margin-top: calc((1em + 15vw) * -1);
    width: 95%;
    height: auto;
}
@media (max-width: 1625px) {
    .img {        
        margin-top: calc((1em + 17vw) * -1);
        width: 490px;
    }
}
@media (max-width: 1380px) {
    .img {        
        margin-top: calc((1em + 17vw) * -1);
        width: 420px;
    }
}
@media (max-width: 1200px) {
    .img {        
        margin-top: calc((1em + 20vw) * -1);
    }
}
@media (max-width: 1100px) {
    .img {        
        margin-top: calc((1em + 20vw) * -1);;
        width: 380px;
    }
}
@media (max-width: 900px) {
    .img {        
        margin-top: calc((1em + 17vw) * -1);
        width: 340px;
    }
}
@media (max-width: 768px) {
    .img {
        margin-top: 0;
        max-height: 50vh;
        width: auto;
    }

}
.bg_line {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 130px;
    background-color: #ECF2F4;
    z-index: -1;
}

@media (max-width: 768px) {
    .bg_line {
        display: block;
    }
}