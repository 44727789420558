.buttons {
    max-width: 1600px;
    margin: 100px auto 0;
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
}

@media (max-width: 1625px) {
    .buttons {        
        padding: 0 25px;
    }
}
@media (max-width: 1310px) {
    .buttons {        
        margin-top: 150px;
        gap: 20px;
        justify-content: center;
    }
}
@media (max-width:768px) {
    .buttons {
        margin: 50px auto 0;
        padding: 0 15px;
        gap: 20px;
    }
}

.btn {
    cursor: pointer;
    width: 236px;
    padding: 15px 40px;
    border-radius: 20px;
    border: 2px solid #3C8127;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    color: #3C8127;
    text-align: center;
    text-transform: uppercase;
    transition: .2s;
    text-decoration: none;
    &:hover {
        background: #538146;
        color: #FFFFFF;
    }
    &:active {
        background: #436938;
    }
}

@media (max-width:900px) {
    .btn {
        flex: 0 0 30%;
        width: auto;
        padding: 15px;
    }
}

@media (max-width:768px) {
    .btn {
        flex: 1 0 40%;
        width: auto;
        padding: 15px;
    }
}