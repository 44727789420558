.wrapper {
    margin: 150px 0 0;
    background-color: #ECF2F4;
    padding: 100px 0;
}
@media (max-width: 1625px) {
    .wrapper {        
        padding: 70px 25px;
    }
}
@media (max-width: 768px) {
    .wrapper {
        margin: 50px 0 0;
        padding: 20px 15px;
    }
}

.map {
    max-width: 1600px;
    margin: 0 auto;
}

.title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1.08px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .title {
        font-size: 20px;
    }
}

.mab_body {
    margin-top: 50px;
    display: flex;
    gap: 40px;
}

@media (max-width: 768px) {
    .mab_body {
        margin-top: 20px;
        flex-direction: column-reverse;
    }
}

.points {
    flex: 1 1 50%;
}

.point_title {
    display: flex;
    gap: 10px;

    &>div:first-child {
        flex: 0 0 25px;
    }

    &>div:last-child {
        flex: 1;
    }
}

.map_img {
    flex: 1 1 50%;

    img {
        max-width: 100%;
    }
}