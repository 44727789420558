.footer {
    position: relative;
    margin-top: 250px;
    border-top: 4px solid #000000;
    padding-top: 120px;
    font-size: 20px;

    a {
        color: #000000;
        text-decoration: none;

        &:hover {
            color: #538146;
            text-decoration: underline;
        }

        &:active {
            color: #436938;
        }
    }
}

@media (max-width: 1625px) {
    .footer {
        padding: 120px 25px 0;
    }
}

@media (max-width: 768px) {
    .footer {
        margin-top: 100px;
        padding-top: 80px;
        font-size: 16px;
    }
}

.left {
    position: absolute;
    top: -130px;
    left: 0;
}

@media (max-width: 768px) {
    .left {
        width: 120px;
        top: -70px;
    }
}

.right {
    position: absolute;
    top: -130px;
    right: 0;
}

@media (max-width: 768px) {
    .right {
        display: none;
    }
}

.footer_body {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .footer_body {
        padding: 0 15px;
    }
}

.blocks {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .blocks {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
}

.left_block,
.middle_block,
.right_block {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media (max-width: 768px) {

    .left_block,
    .middle_block,
    .right_block {
        text-align: center;
    }
}

.right_block {
    justify-content: space-between;
}

.social {
    display: flex;
    justify-content: space-between;

    svg:hover {
        cursor: pointer;
        fill: #ECF2F4;
    }

}

.under_block {
    margin: 60px 0;
    align-self: center;
}