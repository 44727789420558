.title {
    margin: 50px 0 70px;
    padding: 30px 0;
    text-align: center;
    background-color: #ECF2F4;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.56px;
}

@media (max-width: 768px) {
    .title {
        position: relative;
        margin: 15px 0;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        padding: 15px 0 15px 20px;
    }
}

.arrow {
    display: none;
    position: absolute;
    left: 5px;    
    top: 33%;
    transform: translateY(-50%);
    transform: rotate(180deg);
    width: 24px;
    height: 24px;
    fill: #000000;
}

@media (max-width: 768px) {
    .arrow {
        display: block;       
    }
}

.points,
.nav_body {
    max-width: 1600px;
    margin: 0 auto;
}

@media (max-width: 1625px) {
    .points {        
        padding: 0px 25px;
    }
}

@media (max-width: 768px) {
    .points {
       padding: 0 15px; 
    }
    
}
.points_title {
    margin-bottom: 40px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1.08px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .points_title {
        text-align: center;
        font-size: 20px;
    }
}

.items {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.item {
    cursor: pointer;
    flex: 0 1 30%;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        transition: 1s;

        &:hover {
            transform: scale(1.1)
        }
    }

    .item_name {
        position: absolute;
        width: 100%;
        object-fit: cover;
        left: 0;
        bottom: 0;
        padding: 15px 20px;
        background: rgba(48, 104, 31, 0.10);
        backdrop-filter: blur(2.5px);
        font-size: 24px;
        color: #FFFFFF;
        line-height: 150%;
        display: flex;
        gap: 5px;
    }
}

@media (max-width: 1625px) {
    .item {        
        flex: 0 1 45%
    }
}

@media (max-width: 768px) {
    .item {
        flex: 1 1 90%;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid #538146;
        border-radius: 8px;
        padding: 10px;
        &:hover,
        &:active {
            background: #ECF2F4;
        }
        img {
            flex: 0 1 30%;
            border-radius: 10px;
            width: 90px;
        }
        .item_name {
            all: unset;
            flex: 1 0 70%;
            position: static;
            color: #111111;
            font-weight: 600;
            display: flex;
            gap: 5px;
        }
    }
}