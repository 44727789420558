.object {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .object {
        padding: 0 15px;
    }
}

@media (max-width: 768px) {
    .title_body {
        position: relative;
        margin: 15px 0;
        width: 100vw;
        background-color: #ECF2F4;
    }
}

.title {
    margin-top: 50px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1.08px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .title {
        position: relative;
        margin: 15px 0;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5px;
        padding: 10px 0 10px 40px;
    }
}

.arrow {
    display: none;
    position: absolute;
    left: 15px;    
    top: 35%;
    transform: translateY(-50%);
    transform: rotate(180deg);
    width: 24px;
    height: 24px;
    fill: #000000;
    z-index: 10;
}

@media (max-width: 768px) {
    .arrow {
        display: block;
    }
}

.swiper {
    width: 50%;
    position: relative;
    user-select: none;

    img {
        max-width: 83%;
    }
}

@media (max-width: 1625px) {
    .swiper {
        width: 60%;

        img {
            max-width: 83;
            border-radius: 10px;
        }
    }
}

@media (max-width: 768px) {
    .swiper {
        width: 100%;

        img {
            max-width: 100%;
            border-radius: 10px;
        }
    }
}

.text {
    margin-top: 50px;
    width: 45%;
    font-size: 20px;
    p:not(:last-child) {
        margin-bottom: 15px;
    }
}

@media (max-width: 1625px) {
    .text {
        margin-top: 35px;
    width: 60%;
    font-size: 18px;
    }
}

@media (max-width: 768px) {
    .text {
        margin-top: 25px;
        width: 100%;
        font-size: 16px;
    }
}

.map_title {
    margin: 60px 0 30px;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.56px;
}
@media (max-width: 768px) {
    .map_title {
        margin: 40px 0 20px;
        font-size: 16px;
    }
}
.nav_links {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 0 15px;

    a {
        font-size: 20px;
        color: #538146;
        font-weight: 600;
        line-height: 140%;
        border-radius: 20px;
        padding: 5px 20px;
        transition: .3s;

        &:hover {
            background: #ECF2F4;
        }
    }
}

@media (max-width: 768px) {
    .nav_links {
        display: none;
    }
}