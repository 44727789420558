.languages_container {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #111111;
    position: relative;
    user-select: none;
    z-index: 10;
}

.selected_language {
    svg {
        position: relative;
        width: 30px;
        height: 30px;
        top: -2px;
        transition: .3s;
    }

    &:hover,
    &:hover svg {
        color: #538146;
        fill: #538146;
    }

    &:active,
    &:active svg {
        color: #436938;
        fill: #436938;
    }
}

.languages {
    border-radius: 8px;
    position: absolute;
    top: 130%;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(147, 147, 147, 0.15);
    z-index: 10;

    .lan_item {
        &:first-child {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }

        background: #538146;
        color: #FFFFFF;
    }

    div {
        padding: 10px 25px;

        &:not(.lan_item):hover {
            color: #538146;
        }
    }
}