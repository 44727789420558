  .swiper {
    padding: 30px 0 50px;
  }

  @media(max-width: 768px) {
    .swiper {
      padding: 25px 0 35px;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
  }

  @media(max-width: 768px) {
    .swiper-pagination-bullet {
      height: 8px;
      width: 8px;
    }
  }

  .swiper-pagination-bullet-active {
    background: #538146;
  }

  .swiper-button-prev {
    left: 0;
    color: #111111;
    width: 60px;
    height: 60px;
    text-align: center;
  }

  .swiper-button-next {
    right: 0;
    color: #111111;
    width: 60px;
    height: 60px;
  }

  @media(max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }


  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: #538146;
    color: #FFFFFF;
    border-radius: 50%;
  }

  .swiper-button-prev:active,
  .swiper-button-next:active {
    background-color: #436938;
    color: #FFFFFF;
    border-radius: 50%;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 25px;
    font-weight: bold;

  }

  .swiper-button-prev:after {
    margin-left: -3px;
  }

  .swiper-button-next:after {
    margin-right: -3px;
  }