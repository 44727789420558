.header {
    max-width: 1600px;
    margin: 35px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Monrope';
}

@media (max-width: 1625px) {
    .header {
        margin: 0;
        padding: 25px;
    }
}

@media (max-width: 768px) {
    .header {
        margin: 0;
        padding: 15px;
    }
}



.logo_body {
    display: flex;
    align-items: center;
    gap: 15px;
}

@media (max-width: 768px) {
    .logo_body svg {
        width: 70px;
        height: 70px;
    }
}
.text {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}

@media (max-width: 768px) {
    .text {
        font-size: 12px;
    }
}