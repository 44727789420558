@font-face {
  font-family: 'Monrope';
  src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Monrope';
  src: url('./assets/fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Monrope';
  src: url('./assets/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Monrope';
  src: url('./assets/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Monrope';
  src: url('./assets/fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
body {
  box-sizing: border-box;
  font-family: 'Monrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent; 
}

a {
  text-decoration: none;
}