.mapboxgl-ctrl-top-left {
  display: none;
}

.mapboxgl-ctrl-logo {
  position: relative;
  bottom: 20px;
}

.mapboxgl-ctrl-bottom-right {
  left: 0;
  right: auto;
}

.geolocation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #E05B5B;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #E05B5B;
    border-radius: 50%;
  }
}

.map_point {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: red;
    color: #FFFFFF;
  }

  &:active {
    background-color: red;
    color: #FFFFFF;
  }
}

.geolocation_error {
  color: #E05B5B;
  text-align: center;
  font-size: 18px;
  padding-bottom: 5px;
}